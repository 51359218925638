<template>
	<div class="w-full flex">
		<Bunner :ids="id" level="ogv" type="1" class="mr-4"></Bunner>
		<Bunner :ids="id" level="ogv" type="2" class="mr-4"></Bunner>
		<Bunner :ids="id" level="ogv" type="3"></Bunner>
	</div>
</template>

<script>
// Глобальные данные стора
import { actions, getters } from '@/store/store';
import Bunner from '@/components/Bunner.vue';

export default {
	components: { Bunner },
	computed: {
		...getters
	},
	methods: {
		...actions
	},
	data() {
		return {
			id: '0'
		};
	},
	mounted() {
		this.id = this.region.id;

		if (this.id.length > 7) {
			this.getOGVbyId(this.id).then((res) => {
				this.id = res.data.oldId.toString();
			});
		}
	}
};
</script>
